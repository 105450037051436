<template>
  <!-- <div v-loading="loading">
    <div class="box-page">
      <div class="is-flex js-between ai-center pd-b-4">
        <div class="color-bg-black w-100 title-height"></div>
        <div class="w-100 title-height is-flex js-center ai-center">
          <h1 class="size-company">{{ companyName }}</h1>
        </div>
      </div>
      <div v-if="loading == false" class="mg-t-1 pd-x-2">
        <radar-chart :data="chartData" :indicator="indicator" height="500px" />
      </div>
      <div class="font-28 pd-x-2">
        <div
          v-for="item in categorieList"
          :key="item.cateId"
          class="border-bottom is-flex js-between pd-t-5"
        >
          <li>
            {{ item.cateName }}
          </li>
          <div class="font-weight-bold">
            {{ item.point.toFixed(1) }}
          </div>
        </div>
      </div>
    </div>
    <table v-for="item in categorieList" :key="item.cateId" class="page-break" style="width:100%;"> 
      <tr>
        <td style="width:650px;">
          <div class="color-bg-black w-100 title-height"></div>
        </td>
        <td>
          <div class="box-page">
            <div class="is-flex js-between ai-center pd-b-4">
              <div class="w-100 title-height is-flex js-center ai-center">
                <h1 class="size-company">{{ item.cateName }}</h1>
              </div>
            </div>
          </div>
        </td>
      </tr>
      
      <tr>
        <td>
           <radar-chart
            :data="getChartData(item.cateId)"
            :indicator="getIndicator(item.cateId)"
            height="350px"
            radius="45%"
          />
        </td>
        <td>
          <div class="pd-x-2">
          <div class="border-bottom-2 border-black text-right">
            <span class="font-24"
              >ระดับ
              <span class="font-40">{{ item.point.toFixed(1) }}</span></span
            >
          </div>
          <div
            v-for="question in item.question"
            :key="question.questionId"
            class="border-bottom is-flex js-between ai-center pd-t-5 font-14 mg-x-4"
          >
            <li>
              {{ question.questionName }}
            </li>
            <div class="font-weight-bold pd-l-6">
              {{ question.level.toFixed(1) }}
            </div>
          </div>
        </div>
        </td>
      </tr>
      <tr>
        <td>
          <h1 class="mg-y-0">คำแนะนำ</h1>
        </td>
      </tr>
      <tr v-for="question in item.question" :key="question.questionId">
        <td  colspan="2">
          <div class="border-bottom overflow-hidden">
            <h1 class="mg-y-0 float-left">{{ question.questionName }}</h1>
            <h1 class="mg-y-0 float-right">{{ question.level.toFixed(1) }}</h1>
          </div>
          <div class="pd-y-5">
            <viewer
              :initialValue="question.extendDescription"
              class="result-des show-desktop"
            />
          </div>
          <p v-if="question.tools.length > 0 && openMenu" class="mg-y-7">
            เครื่องมือ:
            <span
              v-for="(tool, index) in question.tools"
              :key="tool.id"
              class="color-grey"
              >{{ tool.name
              }}{{ index == question.tools.length - 1 ? "" : ", " }}</span
            >
          </p>
          <p v-if="question.course.length > 0 && openMenu" class="mg-y-7">
            หลักสูตร:
            <span
              v-for="(cours, index) in question.course"
              :key="cours.id"
              class="color-grey"
              >{{ cours.name
              }}{{ index == question.course.length - 1 ? "" : ", " }}
            </span>
          </p>
        </td>
      </tr>
    </table>
  </div> -->
  <div v-loading="loading" class="onPrint">
    <div class="box-page">
      <div class="is-flex js-between">
        <div style="width: 100%" class="is-flex ai-center js-center">
          <img src="@/assets/logo.svg" alt="" style="height: 120px" />
        </div>
        <div class="box-company-name">
          <h1 class="size-company">{{ companyName }}</h1>
        </div>
      </div>
      <div v-if="loading == false" class="mg-t-1 pd-x-2">
        <radar-chart
          :nameFontSize="19"
          :data="chartData"
          :axisLabel="true"
          :axisSize="22"
          :indicator="indicator"
          height="500px"
        />
      </div>
      <div>
        <el-row :gutter="20">
          <el-col :span="24" class="is-flex js-center">
            <ul class="level-result font-14 mg-t-0">
              <li class="mg-b-7">
                Level 1 ไม่มีความพร้อม/ มีระดับความพร้อมในการส่งออกต่ำ
              </li>
              <li class="mg-b-7">
                Level 2 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างต่ำ
              </li>
              <li class="mg-b-7">
                Level 3 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างสูง
              </li>
              <li class="mg-b-7">Level 4 มีระดับความพร้อมในการส่งออกสูง</li>
            </ul>
          </el-col>
        </el-row>
      </div>
      <div class="font-32 pd-x-2">
        <div
          v-for="item in categorieList"
          :key="item.cateId"
          class="border-bottom is-flex js-between pd-t-5"
        >
          <li>
            {{ item.cateName }}
          </li>
          <div class="font-weight-bold">
            {{ item.point.toFixed(1) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="item in categorieList"
      :key="item.cateId"
      class="box-page page-break"
    >
      <div class="pd-b-4">
        <div class="is-flex js-between">
          <div style="width: 100%" class="is-flex ai-center js-center">
            <img src="@/assets/logo.svg" alt="" style="height: 120px" />
          </div>
          <div class="box-company-name">
            <h1 class="size-company">{{ companyName }}</h1>
          </div>
        </div>
        <div class="text-right">
          <h1 class="size-side">{{ item.cateName }}</h1>
        </div>
      </div>
      <div
        v-if="loading == false"
        class="mg-t-1 pd-x-2 is-flex js-between ai-center"
      >
        <div class="box-radar">
          <radar-chart
            :data="getChartData(item.cateId)"
            :indicator="getIndicator(item.cateId)"
            :nameFontSize="15"
            :axisLabel="true"
            :axisSize="16"
            height="350px"
            radius="45%"
          />
        </div>
        <div class="pd-x-2">
          <div class="border-bottom-2 border-black text-right">
            <span class="font-28"
              >ระดับ
              <span class="font-44">{{ item.point.toFixed(1) }}</span></span
            >
          </div>
          <div
            v-for="question in item.question"
            :key="question.questionId"
            class="border-bottom is-flex js-between ai-center pd-t-5 font-18"
          >
            <li>
              {{ question.questionName }}
            </li>
            <div class="font-weight-bold pd-l-6">
              {{ question.level.toFixed(1) }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == false">
        <div>
          <el-row :gutter="20">
            <el-col :span="15" class="is-flex js-center">
              <ul class="level-result font-14 mg-t-0">
                <li class="mg-b-7">
                  Level 1 ไม่มีความพร้อม/ มีระดับความพร้อมในการส่งออกต่ำ
                </li>
                <li class="mg-b-7">
                  Level 2 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างต่ำ
                </li>
                <li class="mg-b-7">
                  Level 3 มีระดับความพร้อมในการส่งออกปานกลางค่อนข้างสูง
                </li>
                <li class="mg-b-7">Level 4 มีระดับความพร้อมในการส่งออกสูง</li>
              </ul>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="pd-x-2">
        <h1 class="mg-y-0" style="page-break-before: always">คำแนะนำ</h1>

        <div
          v-for="question in item.question"
          :key="question.questionId"
          class="pd-b-5"
          style="page-break-inside: avoid"
        >
          <div class="border-bottom overflow-hidden">
            <h2 class="mg-y-0 float-left">{{ question.questionName }}</h2>
            <h1 class="mg-y-0 float-right">{{ question.level.toFixed(1) }}</h1>
          </div>
          <div class="pd-y-5">
            <div
              class="editor-contents result-des show-desktop"
              v-html="question.extendDescription"
            ></div>
            <!-- <viewer
              :initialValue="question.extendDescription"
              class="result-des show-desktop"
            /> -->
          </div>

          <p v-if="question.tools.length > 0 && !openMenu" class="mg-y-7">
            เครื่องมือ:
            <span
              v-for="(tool, index) in question.tools"
              :key="tool.id"
              class="color-grey"
              >{{ tool.name
              }}{{ index == question.tools.length - 1 ? "" : ", " }}</span
            >
          </p>
          <p v-if="question.course.length > 0 && !openMenu" class="mg-y-7">
            หลักสูตร:
            <span
              v-for="(cours, index) in question.course"
              :key="cours.id"
              class="color-grey"
              >{{ cours.name
              }}{{ index == question.course.length - 1 ? "" : ", " }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import RadarChart from "@/echart/Radar";
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";

export default {
  components: {
    DashboardTemplate,
    RadarChart
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    token() {
      return this.$store.state.token;
    },
    openMenu() {
      return this.$store.state.openMenu;
    }
  },
  data() {
    return {
      chartData: [],
      indicator: [],
      companyName: "",
      loading: true,
      categorieList: []
    };
  },
  mounted() {
    this.fetchStatusMenu();
    this.fetchResult();
  },
  methods: {
    fetchStatusMenu() {
      let obj = {
        token: this.token
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${obj.token}`;
      HTTP.get(`close/function`)
        .then(res => {
          if (res.data.success) {
            let status = res.data.obj.status == 1 ? true : false;
            this.$store.commit("SET_OPEN_MENU", status);
          }
        })
        .catch(e => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("FETCH_OPEN_MENU error", e.response);
        });
    },
    async fetchResult() {
      let obj;
      let url = `recommend/result`;
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      if (this.user.role == 0) {
        obj = {
          userId: this.user.id
        };
        let res = await HTTP.post(url, obj);
        this.compareData(res);
      } else {
        if (this.$route.params.status == 0) {
          url = `admin/default/result/${this.$route.params.companyId}`;
          let res = await HTTP.get(url);
          this.compareData(res);
        } else {
          url = `/recommend/result/admin`;
          obj = {
            companyId: this.$route.params.companyId
          };
          let res = await HTTP.post(url, obj);
          this.compareData(res);
        }
      }
    },
    compareData(res) {
      if (res.data.success) {
        this.companyName = res.data.obj.companyName;
        this.categorieList = res.data.obj.categories.sort((a, b) => {
          let modifier = 1;
          if (a.cateId < b.cateId) return -1 * modifier;
          if (a.cateId > b.cateId) return 1 * modifier;
          return 0;
        });
        this.indicator = [];
        this.chartData = [
          {
            value: [],
            name: ""
          }
        ];
        res.data.obj.categories.map((item, index) => {
          if (index == 0) {
            this.indicator.push({
              name:
                this.widthMax > 768
                  ? this.categorieList[index].cateName.replace("และ", "\nและ")
                  : this.addNewLine(
                      this.categorieList[index].cateName,
                      index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                    ),
              max: 5
            });
            this.chartData[0].value.push(
              Number(this.categorieList[index].point.toFixed(1))
            );
          } else if (index == 1) {
            this.indicator.push({
              name:
                this.widthMax > 768
                  ? this.categorieList[4].cateName
                      .replace("และ", "\nและ")
                      .replace("การจัดการ", "การจัดการ\n")
                  : this.addNewLine(
                      this.categorieList[4].cateName,
                      index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                    ),
              max: 5
            });
            this.chartData[0].value.push(
              Number(this.categorieList[4].point.toFixed(1))
            );
          } else if (index == 2) {
            this.indicator.push({
              name:
                this.widthMax > 768
                  ? this.categorieList[3].cateName.replace("และ", "\nและ")
                  : this.addNewLine(
                      this.categorieList[3].cateName,
                      index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                    ),
              max: 5
            });
            this.chartData[0].value.push(
              Number(this.categorieList[3].point.toFixed(1))
            );
          } else if (index == 3) {
            this.indicator.push({
              name: this.categorieList[2].cateName.replace("และ", "\nและ"),
              max: 5
            });
            this.chartData[0].value.push(
              Number(this.categorieList[2].point.toFixed(1))
            );
          } else if (index == 4) {
            this.indicator.push({
              name:
                this.widthMax > 768
                  ? this.categorieList[1].cateName.replace("และ", "\nและ")
                  : this.addNewLine(
                      this.categorieList[1].cateName,
                      index == 0 ? 100 : this.widthMax < 376 ? 6 : 10
                    ),
              max: 5
            });
            this.chartData[0].value.push(
              Number(this.categorieList[1].point.toFixed(1))
            );
          }
        });
      }
      this.loading = false;
      setTimeout(() => {
        window.focus();
        window.print();
        if (
          navigator.userAgent.toLowerCase().indexOf("android") < 1 &&
          navigator.userAgent.indexOf("Firefox") === -1
        ) {
          window.close();
        }
      }, 1200);
    },
    getIndicator(cate) {
      let filter = this.categorieList.filter(filter => filter.cateId == cate);
      if (filter.length > 0) {
        let arr = [];
        filter[0].question.map((item, index) => {
          if (index == 0) {
            arr.push({
              name: this.addNewLine(item.questionName, index == 0 ? 21 : 12),
              max: 5,
              color: "#000"
            });
          } else {
            arr.push({
              name: this.addNewLine(
                filter[0].question[filter[0].question.length - index]
                  .questionName,
                index == 0 ? 21 : 12
              ),
              max: 5,
              color: "#000"
            });
          }
        });
        return arr;
      } else {
        return [];
      }
    },
    getChartData(cate) {
      let filter = this.categorieList.filter(filter => filter.cateId == cate);
      if (filter.length > 0) {
        //console.log("questions", filter);
        let arr = [
          {
            value: [],
            name: ""
          }
        ];
        filter[0].question.forEach((element, index) => {
          if (index == 0) {
            arr[0].value.push(Number(element.level));
          } else {
            arr[0].value.push(
              Number(
                filter[0].question[filter[0].question.length - index].level
              )
            );
          }
        });
        return arr;
      } else {
        return [];
      }
    }
  }
};
</script>

<style scoped></style>
